@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
}

html.dark {
}

body {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

.html-content-container img {
  width: 100% !important;
}
